import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

//Components
import Dashboard from '@/views/Dashboard';

const routes = [
    {
        path: '/',
        name: 'login',
        meta:{
            isPublic:true,
        },
        component: () => import('@/views/Login')
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => import('@/views/Logout')
    },
    {
        path: '/login/:token',
        name: 'login-token',
        meta: {
            isPublic: true,
        },
        component: () => import('@/views/Login')
    },
    {
        path: '/home',
        children: [
            {
                path: '/home',
                name: 'home',
                component: () => import('@/views/Home')
            },
            {
                path: '/home/:msg/:url',
                name: 'home',
                component: () => import('@/views/Home')
            },
            {
                path: '/client/create',
                name: 'create-client',
                component: () => import('@/views/CreateClient')
            },
            {
                path: '/client/list',
                name: 'list-client',
                component: () => import('@/views/ClientList')
            },
            /*{
                path: '/roles',
                name: 'roles',
                component: () => import('@/views/Roles')
            },*/
            {
                path: '/clients-groups',
                name: 'ClientGroups',
                component: () => import('@/views/ClientGroups')
            },
            {
                path: '/clients-scopes',
                name: 'ClientScopes',
                component: () => import('@/views/ClientScopes')
            },
            {
                path: '/clients-roles',
                name: 'ClientRoles',
                component: () => import('@/views/ClientRoles')
            },
            {
                path: '/userlist',
                name: 'userlist',
                component: () => import('@/views/UserList')
            },
        ],
        component: Dashboard
    },
    {
        path: '/twofa',
        name: 'twofa',
        meta: {
            isPublic: true,
        },
        component: () => import('@/views/TwoFactorAuthentication')
    },
    {
        path: '/verification/:type',
        name: 'verification',
        meta: {
            isPublic: true,
        },
        component: () => import('@/views/VerificationForm')
    },
    {
        path: '/save-browser',
        name: 'save-browser',
        meta: {
            isPublic: true,
        },
        component: () => import('@/views/SaveBrowser')
    },
    {
        path: '/user/reset-password',
        name: '/reset-password-user',
        component: () => import('@/views/ResetUsername')
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        meta: {
            isPublic: true,
        },
        component: () => import('@/views/ResetUsername')
    },
    {
        path: '/password_reset/:token',
        name: 'password-reset',
        meta: {
            isPublic: true,
        },
        component: () => import('@/views/PasswordResetCode')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('@/views/About')
    },
    {
        path: '/recover-email',
        name: 'recover-email',
        meta: {
            isPublic: true,
        },
        component: () => import('@/views/ResetUsername')
    },

    {
        path: "*",
        meta: {
            isPublic: true,
        },
        component: () => import('@/views/PageNotFound')
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;
