<template>
    <div class="home ce-main-wrapper ce-sso-dash" :class="{'ce-sso-dashno-nav': !isAdmin}">
        <header-area v-on:toggleSidenav="toggleNav"></header-area>

        <div class="ce-wrapper" :class="{'ce-collapsed' : toggle}">
            <side-nav></side-nav>
            <router-view></router-view>
        </div>

        <footer-area></footer-area>
    </div>
</template>

<script>
    import {GETTER_IS_ADMIN} from "../store/modules/User";
    import {ACTION_CHECK_ROLE} from "../store/modules/User";
    import {mapGetters} from 'vuex';
    import {mapActions} from 'vuex';

    import Header from '../components/Header';
    import Footer from '../components/Footer';
    import SideNav from '../components/SideNav';

    export default {
        name: 'home',
        data() {
            return {
                toggle: false
            }
        },
        components: {
            'header-area': Header,
            'footer-area': Footer,
            'side-nav': SideNav
        },
        methods: {
            toggleNav(value) {
                this.toggle = value
                // console.log("that " + this.toggle);
            },
            ...mapActions('User', {
                checkRole: ACTION_CHECK_ROLE
            }),
        },
        computed: {
            // checkAdmin(){
            //     console.log("check " + GETTER_IS_ADMIN);
            //     return true;
            // },
            ...mapGetters('User', {
                isAdmin: GETTER_IS_ADMIN
            }),
            //
        },
        mounted() {
            this.checkRole();
        }
    }
</script>
