import router from '../../router';
import axios from 'axios';
import Api from '../../includes/Api';
import Helper from "../../includes/Helper";

//Getters
export const GETTER_IS_LOGGED_IN = 'is_logged_in';
export const GETTER_IS_ADMIN = 'is_admin';
export const GETTER_IS_CLIENT_REDIRECT = 'is_client_redirect';


//Mutations
export const MUTATION_LOGIN = 'SET_USER_DETAILS';
export const MUTATION_LOGOUT = 'CLEAR_USER_DETAILS';
export const MUTATION_SET_CLIENT = 'MUTATION_SET_CLIENT';
export const MUTATION_SET_EMAIL = 'MUTATION_SET_EMAIL';

//Actions
export const ACTION_APP_INIT = 'ACTION_APP_INIT';
export const ACTION_LOGIN = 'login';
export const ACTION_LOGOUT = 'logout';
export const ACTION_AUTHENTICATE_TYPE = 'authenticate_type';
export const ACTION_AUTHENTICATE_CODE = 'authenticate_code';
export const ACTION_SAVE_BROWSER = 'save_browser';
export const ACTION_CHECK_ROLE = 'checkRole'

export default {
    namespaced: true,
    state: {
        toClient: null,
        user: null,
        accessAllowed: null,
        admin_email: []
    },
    getters: {
        [GETTER_IS_LOGGED_IN](state) {
            //if ( ! state.user ) {
            //    return 1;
           // } else {
                return !!state.user && !!window.localStorage.getItem('SSO_ACCESS_TOKEN');
            //}
        },
        [GETTER_IS_ADMIN](state) {
            return !!state.user && state.admin_email.indexOf(state.user.email) > -1;
        },
        [GETTER_IS_CLIENT_REDIRECT](state) {
            return state.toClient !== null;
        }
    },
    mutations: {
        [MUTATION_LOGIN](state, data) {
            state.user = data;
            if (state.user.access_token) {
                //Also set the access token to the localstorage
                window.localStorage.setItem('SSO_ACCESS_TOKEN', state.user.access_token);
                //Set same token as axios default authorization header
                axios.defaults.headers['Authorization'] = 'Bearer ' + state.user.access_token;
            }
        },
        [MUTATION_LOGOUT](state) {
            state.user = null;
            //Clear access token
            window.localStorage.removeItem('SSO_ACCESS_TOKEN');
        },
        [MUTATION_SET_CLIENT](state, data) {
            state.toClient = data;
        },
        [MUTATION_SET_EMAIL](state, data) {
            state.admin_email.push(data);
        }
    },
    actions: {
        [ACTION_APP_INIT]({state, commit}, app) {
            let token = window.localStorage.getItem('SSO_ACCESS_TOKEN');
            if (token) {
                axios.defaults.headers['Authorization'] = 'Bearer ' + token;
                // console.log("has token");
            }
            let urlParams = new URLSearchParams(window.location.search);
            if (urlParams.has('site_key')) {
                axios.defaults.headers['SiteKey'] = urlParams.get('site_key');
            }
            Api.get('/app-init').then((response) => {
                if (response.data.user) {
                    commit(MUTATION_LOGIN, response.data.user);
                }
                if (response.data.client) {
                    commit(MUTATION_SET_CLIENT, response.data.client);
                }
            }).catch(() => {
                commit(MUTATION_LOGOUT);
                //User is not logged in. Redirect to Login page.
                if (router.currentRoute.meta.isPublic !== true) {
                    router.push('/');
                }
            }).then(() => {
                //We want this to run regardless of whether the app init is success or not.
                if (state.toClient && state.toClient.redirectUrl) {
                   window.location.href = state.toClient.redirectUrl; //This wll include the token for the domain.
                } else {
                    //We need this timeout to prevent running the created event of the component of route being accessed directly.
                    setTimeout(function () {
                        app.$mount('#app');
                    }, 100);
                }
            });
        },
        [ACTION_LOGIN]({state, commit}, credentials) {
          //  console.log(credentials);return false;
            Api.post('/login', {
                ...credentials,
                browser_code: window.localStorage.getItem('SSO_REMEMBER_ME')
            }).then((response) => {
                commit(MUTATION_LOGIN, response.data.user);
                if (response.data.client) {
                    commit(MUTATION_SET_CLIENT, response.data.client);
                }
                if (state.user.access_token) { //Response will not have a token if current browser is not saved.
                    Helper.performRedirect(state);
                } else {
                    router.push('/twofa');
                }
            });
        },
        [ACTION_LOGOUT]({commit}) {
            Api.get('/logout').then(() => {
                commit(MUTATION_LOGOUT);
                router.push('/');
                Helper.hideSpinner();
            });
        },
        [ACTION_AUTHENTICATE_TYPE]({state}, data) {
            Api.post('/authenticate', {data: data, user_id: state.user.id}).then(() => {
                router.push('/verification/' + data);
            });
        },
        [ACTION_AUTHENTICATE_CODE]({state, commit}, data) {
            Api.post('/authenticate-code', {data: data, user_id: state.user.id}).then((response) => {
                if (response.data.user) {
                    commit(MUTATION_LOGIN, response.data.user);
                }
                if (response.data.client) {
                    commit(MUTATION_SET_CLIENT, response.data.client);
                }
                router.push('/save-browser');
            });
        },
        [ACTION_SAVE_BROWSER]({state}, data) {
            if (data === 'yes') {
                Api.post('/save-browser', {user_id: state.user.id}).then((response) => {
                    window.localStorage.setItem('SSO_REMEMBER_ME', response.data.browser_code);

                    Helper.performRedirect(state);
                });
            } else {
                Helper.performRedirect(state);
            }
        },
        [ACTION_CHECK_ROLE]({state, commit}) {
            let data = {'userId': state.user.id};
            Api.post('/check-role', data).then((response) => {
                if(response.data.role == "valid"){
                    commit(MUTATION_SET_EMAIL, response.data.email);
                }
            });
        }
    }
}
