import Vue from 'vue';
import axios from 'axios';

export default class Api {
    static getUrl(url) {
        return process.env.VUE_APP_API_BASE + url;
    }

    static get(url) {
        return new Promise((resolve, reject) => {
            return axios.get(Api.getUrl(url)).then((response) => {
                Api.resolve(response, resolve, reject);
            }).catch((error) => {
                Api.reject(error, reject);
            });
        });
    }

    static post(url, data) {
        return new Promise((resolve, reject) => {
            return axios.post(Api.getUrl(url), data).then((response) => {
                Api.resolve(response, resolve, reject);
            }).catch((error) => {
                Api.reject(error, reject);
            });
        });
    }

    //Helper function
    static resolve(response, resolve, reject) {
        if (response.data.status === 'success') {
            resolve(response);
        } else if (response.data.status === 'error') {
            Api.alert('error', response.data.message);
            reject(response);
        } else {
            Api.alert('error', 'Failed!');
            reject(response);
        }
    }

    static reject(error, reject) {
        Api.alert('error', error.message);
        reject(error);
    }

    static alert(status, message) {
        switch (status) {
            case 'success':
                Vue.notify({
                    group: 'alert',
                    title: 'Success!',
                    type: 'success',
                    text: message
                });
                break;
            case 'error':
                Vue.notify({
                    group: 'alert',
                    title: 'Error!',
                    type: 'error',
                    text: message,
                });
                break;
            case 'warning':
                Vue.notify({
                    group: 'alert',
                    title: 'Warning!',
                    type: 'warn',
                    text: message
                });
                break;
            case 'info':
                Vue.notify({
                    group: 'alert',
                    title: 'Info!',
                    text: message
                });
                break;
        }
    }
}