<template>
    <header class="ce-header">
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <a class="navbar-brand" href="#"><img src="https://performance-health-logo.s3.us-east-2.amazonaws.com/logo.png" alt=""></a>
            <div class="ce-navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li v-on:click="changeSideNav" class="nav-item ce-hamberg active">
                        <a href="javascript:void(0);"><i class="flaticon-menu"></i></a>
                    </li>
                </ul>
                <div class="ml-auto ce-admin-wrap">

                    <ul class="ce-admin-user-area">
                        <li class="ce-admin-user-title">
                            <div class="dropdown">
                                <a v-on:click="toggleDropdown = !toggleDropdown"
                                   class="btn btn-secondary dropdown-toggle ce-btn-admin" href="#" role="button"
                                   id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                   aria-expanded="false">
                                    <span><i class="fa fa-ellipsis-v" aria-hidden="true"></i></span>
                                </a>
                                <div class="dropdown-menu" :class="{ show: toggleDropdown }"
                                     aria-labelledby="dropdownMenuLink">
                                    <router-link class="dropdown-item" :to="{path:'/user/reset-password'}" tag="a" exact>
                                        Reset Password
                                    </router-link>
                                    <div class="dropdown-divider"></div>
                                    <router-link class="dropdown-item" :to="{path:'/logout'}" tag="a" exact>
                                        Logout
                                    </router-link>
<!--                                    <a v-on:click.prevent="logout" class="dropdown-item" href="#">Logout</a>-->
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

            </div>
        </nav>
    </header>
</template>
<script>
    import {mapActions} from 'vuex';

    export default {
        data() {
            return {
                toggleDropdown: false,
                toggleSidenav: true
            }
        },
        methods: {
            ...mapActions('User', ['logout']),
            changeSideNav() {
                this.toggleSidenav = !this.toggleSidenav;
                this.$emit('toggleSidenav', this.toggleSidenav)
            }
        }
    }
</script>
