<template>
    <div class="ce-side-nav">
        <div class="ce-side-nav-inner">
            <ul class="ce-side-nav-list">
                <template v-for="route in routes">
                    <template>
                        <router-link active-class="active" :key="route.name"
                                     class="nav-item" :to="route.link" tag="li" exact>
                            <a >
                                <span class="ce-side-nav-list-icon">
                                    <i :class="route.icon"></i>
                                </span>
                                <span class="ce-side-nav-list-item"> {{route.name}}</span>
                            </a>
                        </router-link>
                    </template>
                </template>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SideNav",
        computed: {
            routes: function () {
                return [
                    {
                        name: 'Home',
                        icon: 'flaticon-home',
                        link: {name: 'home'}
                    },
                    {
                        name: 'App List',
                        icon: 'fas fa-th-list',
                        link: {name: 'list-client'}
                    },
                    /*{
                        name: 'Roles',
                        icon: 'fas fa-check-square-o',
                        link: {name: 'roles'}
                    },*/
                    {
                        name: 'App Scopes',
                        icon: 'fas fa-cubes',
                        link: {name: 'ClientScopes'}
                    },
                    {
                        name: 'App Roles',
                        icon: 'fas fa-address-card-o',
                        link: {name: 'ClientRoles'}
                    },
                    {
                      name: 'Groups',
                      icon: 'fas fa-users',
                      link: {name: 'ClientGroups'}
                    },
                    {
                        name: 'User List',
                        icon: 'fas fa-list-ul',
                        link: {name: 'userlist'}
                    }
                ]
            },
        },
    }
</script>
