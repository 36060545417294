import 'bootstrap';

import Vue from 'vue';
import Notifications from 'vue-notification';
import VueCookies from 'vue-cookies';
import App from './App.vue';
import router from './router';
import store from './store';
import Helper from './includes/Helper';
import {ACTION_APP_INIT,GETTER_IS_LOGGED_IN} from "./store/modules/User";

import VueConfirmDialog from 'vue-confirm-dialog';

Vue.config.productionTip = false;
Vue.use(Notifications);
Vue.use(VueCookies);

Vue.use(VueConfirmDialog);
Vue.component('vue-confirm-dialog', VueConfirmDialog.default);

const app = new Vue({
    router,
    store,
    render: h => h(App),
    created() {
        router.beforeEach((to, from, next) => {
            let is_logged_in = store.getters['User/' + GETTER_IS_LOGGED_IN];
            if (to.meta && to.meta.isPublic !== true && !is_logged_in) {
                next('/');
            } else {
                next();
            }
        });
    }
});

Helper.showSpinner();

store.dispatch('User/' + ACTION_APP_INIT, app);


